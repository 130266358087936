window.addEventListener("DOMContentLoaded", () => {

    // slider
    /*
    const myFullpage = new fullpage( "#fullpage", {
        licenseKey: "KBL58-XN1R7-KH9M6-JT2EH-VCVYL",
        //options here
        anchors: [ "banner", "2Page", "3Page", "4Page", "footer" ],
        menu: '#myTabs',

        autoScrolling: true,
        scrollHorizontally: true,
        loopHorizontalL: false,
        showActiveTooltip: false,
        css3: true,
        scrollingSpeed: 1200,
        controlArrows: false,
        credits: false,
        recordHistory: true,
        scrollOverflow: true,
        onLeave: function ( origin, destination ) {
            origin.item.classList.remove("visible")
            destination.item.classList.add("visible")
            if ( origin.anchor === "3Page" ) {
                origin.item.querySelector(".b3").classList.remove("visible")
                origin.item.querySelector(".b6").classList.remove("visible")
            }
            if ( origin.anchor === "4Page" && destination.anchor === "3Page" ) {
                destination.item.querySelector(".b6").classList.add("visible")
            }
        },
        afterSlideLoad: function ( section, origin, destination, direction ) {
            origin.item.classList.remove("visible")
            destination.item.classList.add("visible")
        },
    } );
    */

    try {
        // menu
        const menuListButton = document.querySelector(".menu-wrapper-nav-list__item_btn"),
          listBlock = document.querySelector(".menu-wrapper-nav-list__item .list"),
          burgerMenuButton = document.querySelector(".burger"),
          closeMenuButton = document.querySelector(".menu__close"),
          menuListItem = document.querySelectorAll(".list .parent"),
          menuBlock = document.querySelector(".menu");

        burgerMenuButton.addEventListener("click", (e) => {
            e.preventDefault();

            myFullpage.setAutoScrolling(false);
            document.body.style.overflow = "hidden";
            menuBlock.classList.add("active");

        });

        closeMenuButton.addEventListener("click", (e) => {
            e.preventDefault();
            //document.body.style.overflow = "visible";

            myFullpage.setAutoScrolling(true)
            menuBlock.classList.remove("active");
        });


        menuListButton.addEventListener("click", (e) => {
            e.preventDefault();
            listBlock.classList.toggle("open");
            menuListButton.classList.toggle("active");
        });

        menuListItem.forEach((item) => {
            item.addEventListener("click", (e) => {
                //e.preventDefault();

                item.children[1].classList.toggle("open");
                item.classList.toggle("active");
            })
        })
    } catch ( e ) {
        console.log(e);
    }



    try {
        // dropdown
        const dropdownButton = document.querySelector(".open-dropdown"),
          dropdownBlock = document.querySelector(".dropdown"),
          dropdownLinks = document.querySelectorAll(".dropdown-block-list .parent");


        dropdownButton.addEventListener("mouseover", () => {

            myFullpage.setAllowScrolling(false);
            myFullpage.setKeyboardScrolling(false);

            document.body.style.overflow = "hidden";
            dropdownBlock.classList.add("active");
        });

        const handleEsc = (e) => {
            if ( e.key === "Escape" ) {

                myFullpage.setAllowScrolling(true);
                myFullpage.setKeyboardScrolling(true);

                document.body.style.overflow = "visible";
                dropdownBlock.classList.remove("active");
            }

        };

        document.addEventListener("keydown", handleEsc);

        dropdownBlock.addEventListener("mouseup", (e) => {
            if ( !document.querySelector('.dropdown-container').contains(e.target) ) {
                e.preventDefault();

                myFullpage.setAllowScrolling(true);
                myFullpage.setKeyboardScrolling(true);

                document.body.style.overflow = "visible";
                dropdownBlock.classList.remove("active");
            }

        })

        dropdownLinks.forEach((item) => {
            item.addEventListener("mouseover", (e) => {
                e.preventDefault();
                document.querySelectorAll(".dropdown-block-list ul").forEach((a) => {
                    a.classList.remove("active");
                })
                dropdownLinks.forEach((b) => {
                    b.classList.remove("active");
                })
                item.classList.add("active");
                item.children[1].classList.add("active");
            })
        })
    } catch ( e ) {
        console.log(e);
    }






    try {
        // Форма обратно связи
        const button = document.querySelector(".email-button"),
          closeFormButton = document.querySelector(".form__close"),
          formSection = document.querySelector(".form");


        button.addEventListener("click", (e) => {
            e.preventDefault();

            myFullpage.setAllowScrolling(false);
            myFullpage.setKeyboardScrolling(false);

            document.body.style.overflow = "hidden";
            formSection.classList.add("active");
        });



        closeFormButton.addEventListener("click", (e) => {
            e.preventDefault();

            myFullpage.setAllowScrolling(true);
            myFullpage.setKeyboardScrolling(true);

            document.body.style.overflow = "visible";
            formSection.classList.remove("active");
        });

        // inputmask
        const form = document.querySelector('#form');
        const telSelector = form.querySelector('input[type="tel"]');
        const inputMask = new Inputmask({"mask": "+7 (999) 999-99-99", showMaskOnHover:false});
        inputMask.mask(telSelector);

        const validation = new JustValidate('#form');

        validation
          .addField('.input-name', [
              {
                  rule: 'minLength',
                  value: 2,
              },
              {
                  rule: 'maxLength',
                  value: 30,
              },
              {
                  rule: 'required',
                  value: true,
                  errorMessage: " ",
              },
          ])
          .addField(".input-email", [
              {
                  rule: 'required',
                  value: true,
                  errorMessage: ' ',
              },
              {
                  rule: 'email',
                  value: true,
                  errorMessage: ' ',
              },
          ])
          .addField(".input-check", [
              {
                  rule: 'required',
                  errorMessage: ' ',
              },
          ])
          .addField('.input-tel', [
              {
                  rule: 'required',
                  value: true,
                  errorMessage: " ",
              },
              {
                  rule: 'function',
                  validator: function() {
                      const phone = telSelector.inputmask.unmaskedvalue();
                      return phone.length === 10;
                  },
                  errorMessage: " "
              },

          ]).onSuccess((event) => {
            console.log('Validation passes and form submitted', event);

            let formData = new FormData(event.target);

            console.log(...formData);

            let xhr = new XMLHttpRequest();

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        console.log('Отправлено');
                        //alert('Отправлено')
                    } else {
                        console.log('Не отправлено');
                        //alert('Не Отправлено')
                    }
                }
            }

            xhr.open('POST', 'mail.php', true);
            xhr.send(formData);

            event.target.reset();
        });
    } catch ( e ) {
        console.log(e);
    }



});


